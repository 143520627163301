
<template>
  <FormGroup :title="label" :titleSuffix="labelSuffix" :help="help" :required="required">
    <div v-if="autoComplete" >
      <label>
        {{$t('Zoek je adres')}}
        <span v-if="isRequired" class="required">*</span>
      </label>
      <div ref="placeAutocompleteContainer" class="autocomplete"></div>
      <Grid>
        <Button v-if="addressNotFound" type="button" warning fill @click="autoComplete = false" class="not-found-button">Adres niet gevonden?</Button>
      </Grid>
    </div>
    <Grid v-else class="address" :columns="{ tablet: 4 }">
      <Column :span="2">
        <Input
          :value="localValue.street"
          v-model="localValue.street"
          :label="$t('Straat')"
          :id="`${id}-street`"
          :required="isRequired"
          @click="resetAddress"
          @input="emitAddress"
          />
      </Column>
      <Column>
        <Input
          v-model="localValue.streetNumber"
          :label="$t('Nummer')"
          :id="`${id}-streetNumber`"
          type="text"
          :placeholder="$t('Nummer')"
          :error="isRequired && v?.streetNumber?.$error"
          :required="isRequired"
          @input="emitAddress"
           />
      </Column>
      <Column>
        <Input
          v-model="localValue.bus"
          :label="$t('Bus')"
          :id="`${id}-bus`"
          type="text"
          :placeholder="$t('Bus')"
        />
      </Column>
      <Column>
        <Input
          v-model="localValue.postal"
          :label="$t('Postcode')"
          :placeholder="$t('Postcode')"
          :error="isRequired && v?.postal?.$error"
          :id="`${id}-postal`"
          :required="isRequired"
          @input="emitAddress"
        />
      </Column>
      <Column :span="2">
        <Input
          v-model="localValue.city"
          :label="$t('Stad')"
          :placeholder="$t('Stad')"
          :required="isRequired"
          :id="`${id}-city`"
          :error="isRequired && v?.city?.$error"
          @input="emitAddress"
        />
      </Column>
      <Column>
        <Input
          v-model="localValue.country"
          label="Land"
          placeholder="Land"
          :id="`${id}-country`"
          type="text"
          :error="isRequired && v?.country?.$error"
          @input="emitAddress"
          :required="isRequired"
        />
      </Column>
    </Grid>
  </FormGroup>
</template>
<script setup>

import Column from "@/components/Core/Column.vue";
import Grid from "@/components/Core/Grid.vue";
import FormGroup from "@/components/Core/FormGroup.vue";
import Input from "@/components/Core/Input.vue";
import Button from "@/components/Core/Button.vue";
import { ref, onMounted, reactive, computed } from 'vue'
import { required } from 'vuelidate/lib/validators'

const placeAutocompleteContainer = ref(null)
const autoComplete = ref(true)
const addressNotFound = ref(false)
const observeInput = ref(false)

let localValue = reactive({
  street: '',
  streetNumber: '',
  bus: '',
  city: '',
  postal: '',
  country: ''
})

const { value, label,labelSuffix, isRequired, help, id } = defineProps({
  value: {
    type: [Object],
    default: () => ({
      street: '',
      streetNumber: '',
      bus: '',
      city: '',
      postal: '',
      country: ''
    })
  },
  label: {
    type: String
  },
  labelSuffix: {
    type: String
  },
  isRequired: {
    type: Boolean
  },
  help: {
    type: String
  },
  id: {
    type: String
  },
  v: Object
})
const emit = defineEmits(['input'])
// Conditional validations
const validations = computed(() => {
  if (isRequired) {
    return {
      localValue: {
        street: { required },
        streetNumber: { required },
        postal: { required },
        city: { required },
        country: { required }
      }
    }
  }
  return {} // No validations when not required
})
// load Google Maps
onMounted(async () => {
  await initMap()
  if ( value && value.street) {
    autoComplete.value = false
    localValue.street = value.street
    localValue.streetNumber = value.streetNumber
    localValue.bus = value.bus
    localValue.city = value.city
    localValue.postal = value.postal
    localValue.country = value.country
  }
})
const resetAddress = async () => {
  if (addressNotFound.value) return
  localValue.street = ''
  localValue.number = ''
  localValue.bus = ''
  localValue.postalCode = ''
  localValue.city = ''
  localValue.country = ''
  await initMap()
}
const initMap = async () => {
  autoComplete.value = true
  await google.maps.importLibrary('places')

  const placeAutocomplete = new google.maps.places.PlaceAutocompleteElement({
    locationBias: { radius: 100, center: { lat: 50.8503, lng: 4.3517 } },
    types: ['address']
  })
  placeAutocomplete.attachShadow({mode:"open"}).innerHTML = `
<style>
    .widget-container {
        border: 1px solid #bebebe !important;
        border-radius: 3px !important;
        color: #565656 !important;
        height: 38px !important;
        max-width: 100% !important;
        padding: 7px 12px !important;
        transition: all 300ms linear 0s !important;
    }
    .input-container input {
        max-width: 100% !important;
    }
    .input-container:focus-visible {
        outline: none !important;
    }
    .input-container:focus-visible {
        outline: none !important;
    }
    .focus-ring {
    border: none !important;
    }
    .place-autocomplete-element-row .place-autocomplete-element-text-div .place-autocomplete-element-place-name {
        font-size: 1rem !important;
    }
</style>`
  placeAutocomplete.addEventListener('blur', async (event) => {
    if (!localValue.street) {
      addNotFoundButton()
    }
  })
  placeAutocomplete.addEventListener('input', async (event) => {
    if (observeInput.value && !event.target.predictions.length) {
      addNotFoundButton()
    }
    observeInput.value = true
  })
  placeAutocompleteContainer.value.appendChild(placeAutocomplete)
  placeAutocomplete.addEventListener('gmp-placeselect', async ({ place }) => {
    await place.fetchFields({
      fields: ['addressComponents']
    })
    // Update the reactive variables
    const addressComponents = place.addressComponents
    for (const addressComponent of addressComponents) {
      if (addressComponent.types.includes('street_number')) {
        localValue.streetNumber = addressComponent.longText
      } else if (addressComponent.types.includes('route')) {
        localValue.street = addressComponent.longText
      } else if (addressComponent.types.includes('subpremise')) {
        localValue.bus = addressComponent.longText
      } else if (addressComponent.types.includes('postal_code')) {
        localValue.postal = addressComponent.longText
      } else if (addressComponent.types.includes('sublocality')||addressComponent.types.includes('locality')) {
        localValue.city = addressComponent.longText
      } else if (addressComponent.types.includes('country')) {
        localValue.country = addressComponent.longText
      }
    }

    autoComplete.value = false
    emitAddress()
  })
}

const emitAddress = () => {
  emit('input', {
    street: localValue.street,
    streetNumber: localValue.streetNumber,
    bus: localValue.bus,
    city: localValue.city,
    postal: localValue.postal,
    country: localValue.country
  })
}
const addNotFoundButton = () => {
  addressNotFound.value = true
}
</script>

<style scoped>
.not-found-button {
  margin-left: auto;
  margin-top: 0.5rem;
}

</style>
